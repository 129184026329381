import './App.css';
import { Thumbnail } from './Thumbnail';

function App() {
  const topics = [
    'Wifi',
    'Parking Info',
    'Trash Day',
    'TV/Streaming',
    'Smoking Policy',
    'Grill Usage',
    'Security',
    'Furniture Instructions',
    'Laundry Instructions',
  ];
  return (
    <div className="App">
      <h1 className="title">Welcome to The Jet!</h1>
      <div className="thumbnails">
        {topics.map((topic) => (
          <Thumbnail name={topic} className="thumbnail" />
        ))}
      </div>
    </div>
  );
}

export default App;
