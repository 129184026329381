import React from 'react';
import jet from './jet.jpeg';
import './Thumbnail.css';

export const Thumbnail = ({ name }) => {
  return (
    <div className="thumbnail">
      <img src={jet} alt="" width="75px" />
      <h1>{name}</h1>
    </div>
  );
};
